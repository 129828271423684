import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import IMG from "../../images/Second.svg"

const Second = () => (
  <TutorialLayout
    imageSrc={IMG}
    title="Vrei sa deschizi cu telefonul sau folosind cardul fizic?"
    buttonLink="/salut/stepOne"
    firstRadioText="Folosind cardul fizic"
    secondRadioText="Folosind telefonul"
    firstRadioLink="/salut/finishPhisical"
    secondRadioLink="/salut/stepOne"
  ></TutorialLayout>
)

export default Second
